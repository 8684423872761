(function() {
    'use strict';

    $(function() {
        $('[data-href]').click(function () {
            var $link = $(this);
            var url = $link.attr('data-href');
            var target =  $link.attr('data-target');

            if ('_blank' === target) {
                window.open(url);
                return;
            }

            window.location.href = url;
        });
    });
})();

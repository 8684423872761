(function() {
    'use strict';

    var $form = $('#sort-form');
    var $select = $form.find('select[name=sort]');
    var defaultSorting = $form.attr('data-default');

    $form.change(function() {
        if ($select.val() === defaultSorting) {
            $select.prop('disabled', true);
        }

        $form.submit();
    });
})();

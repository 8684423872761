'use strict';

RegExp.quote = function(str) {
    return String(str).replace(/([.*+?^=!:${}()|\[\]\/\\])/g, "\\$1");
};

var app = {
    mediumBreakpoint: 640,
    largeBreakpoint: 1440,
    fontSizePercent: 85,
    loadMoreInterval: 0,
    mousePosition: {
        x: 0,
        y: 0
    },

    initialize: function() {
        app.initializeFoundation();
        app.initializeFastClick();
        app.initializeDynamicHeights();
        app.initializeWishlistButtons()
        app.initializeTeaserArrow();
        app.initializeWishlistFilter();
        app.initializeDropDowns();
        app.initializeFlash();
        app.initializeShopList();
        app.initializePasswordFields();
        app.initializeHashScrolling();
        app.initializeAjaxForms();
        app.adjustHeaderHeight();
        app.initializeLoadMore();
        app.initializeInsiderTile();
        app.initializeInputTouched();
        app.initializeClipboard();
        app.initializeScrollUp();
        app.fadeInElements();
        app.initializeClickableElements();
        app.disableLinks();
        app.traceMousePosition();
        app.setClientId();
        app.initializeSticky();
        app.initializeSliders();
        app.masonry();
        app.toggleMobileSocialIcons();
        app.initializeScrollToProduct();
        app.initializeLayer();

        $(window).resize(function() {
            app.initializePasswordFields();
            app.toggleMobileSocialIcons();
        });

        $(window).scroll(function() {
            app.toggleMobileSocialIcons();
        });
    },

    masonry: function() {
        $('[data-masonry]').each(function() {
            $(this).masonry();
        });
    },

    initializeSliders: function() {
        $('.slider').each(function() {
            var slider           = $(this);
            var slides           = slider.children('.slides');
            var firstSlide       = slides.children().first();
            var lastSlide        = slides.children().last();
            var prevButton       = slider.children('button.prev');
            var nextButton       = slider.children('button.next');
            var slidesPerClick   = parseInt(slider.attr('data-slides-per-click')) || 1;
            var speed            = parseInt(slider.attr('data-speed')) || 400;
            var scrollBarHeight  = 20;

            var getVisibleSlides = function() {
                var minLeft    = slides.scrollLeft();
                var maxLeft    = minLeft + slides.width();
                var visibles   = [];
                var position   = 0;
                slides.children().each(function() {
                    var slide = $(this);
                    if (position >= minLeft && position <= maxLeft) {
                        visibles.push(slide);
                    }

                    position = position + slide.outerWidth(true);
                });

                return visibles;
            };

            var getCurrentSlide = function() {
                return getVisibleSlides()[0];
            };

            var adjustHeight = function() {
                var height = slides.height() - scrollBarHeight;
                slider.height(height);
            };

            var loadImages = function() {
                var slides = getVisibleSlides();

                for (var i = 0; i < slides.length; i++) {
                    var slide = slides[i];

                    slide.find('img[data-lazy]').each(function() {
                        var img = $(this);
                        var src = img.attr('data-src');

                        img.removeAttr('data-lazy')
                           .removeAttr('data-src')
                           .attr('src', src);
                    });
                }
            };

            nextButton.click(function() {
                var newLeft      = slides.scrollLeft();
                var currentSlide = getCurrentSlide();
                for (var i = 0; i < slidesPerClick; i++) {
                    var width        = currentSlide.outerWidth(true);

                    newLeft      = newLeft + width;
                    currentSlide = currentSlide.next();

                    if (!currentSlide.length) {
                        break;
                    }
                }

                slides.animate({
                    scrollLeft: Math.min(newLeft, slides[0].scrollWidth)
                }, speed, function() {
                    loadImages();
                });
            });

            prevButton.click(function() {
                var newLeft      = slides.scrollLeft();
                var currentSlide = getCurrentSlide();
                for (var i = 0; i < slidesPerClick; i++) {
                    var width = currentSlide.outerWidth(true);

                    newLeft      = newLeft - width;
                    currentSlide = currentSlide.prev();

                    if (!currentSlide.length) {
                        break;
                    }
                }

                slides.animate({
                    scrollLeft: Math.min(newLeft, slides[0].scrollWidth)
                }, speed);
            });

            slides.scroll(function() {
                var position = slides.scrollLeft();
                var min      = firstSlide.width() / 4;
                var max      = slides[0].scrollWidth - slides.width() - lastSlide.width() / 4;

                if (position < min) {
                    prevButton.prop('disabled', true);
                    nextButton.prop('disabled', false);
                } else if (position >= max) {
                    nextButton.prop('disabled', true);
                    prevButton.prop('disabled', false);
                } else {
                    nextButton.prop('disabled', false);
                    prevButton.prop('disabled', false);
                }

                loadImages();
            });

            loadImages();
            adjustHeight();

            window.setInterval(function() {
                adjustHeight();
            }, 500);

            $(window).resize(function() {
                adjustHeight();
            });
        });
    },

    initializeSticky: function() {
        $(function() {
            $('[data-sticky]').each(function() {
                var element = $(this);
                var top     = parseInt(element.attr('data-top'));
                var bottom  = parseInt(element.attr('data-bottom'));

                element.sticky({topSpacing: top, bottomSpacing: bottom});
                element.removeAttr('data-sticky');
            });
        });
    },

    initializeFoundation: function() {
        $(document).foundation({topbar: {
            custom_back_text: true,
            back_text: '&laquo ZURÜCK',
        }});

        var back = $('header .back');
        back.html(back.children('h5').html());
    },

    initializeFastClick: function() {
        FastClick.attach(document.body);
    },

    disableLinks: function() {
        $('a.disabled').click(function(event) {
            event.preventDefault();
            return false;
        });
    },

    fadeInElements: function() {
        $('[data-fade]').each(function() {
            var duration = $(this).attr('data-fade');
            $(this).animate({opacity: 1.0}, duration);
        });
    },

    initializeScrollUp: function() {
        app.setScrollUpPosition();

        $(window).resize(function() {
            app.setScrollUpPosition();
        });

        $('a.scroll-up').click(function() {
            app.scrollTo(0);
        });

        window.setInterval(function () {
            var pos = $(document).scrollTop();

            if (pos > 1000) {
                $('a.scroll-up').fadeIn();
            } else if (pos < 800) {
                $('a.scroll-up').fadeOut();
            }
        }, 1000);
    },

    toggleMobileSocialIcons: function() {
        var width        = $(window).width();
        var windowHeight = $(window).height();;
        var docHeight    = $(document).height();
        var pos          = $(document).scrollTop();
        var icons        = $('.social-icons.mobile');
        var buffer       = 200;

        var isSmallBreakpoint = width < app.mediumBreakpoint;
        var isTopMarginOk     = pos > buffer;
        var isBottomMarginOk  = (pos + windowHeight) < (docHeight - buffer);

        if (isSmallBreakpoint && isTopMarginOk && isBottomMarginOk) {
            icons.fadeIn();
        } else {
            icons.fadeOut();
        }
    },

    setScrollUpPosition: function() {
        var documentWidth = $('body').width();
        var contentWidth = $('footer > nav').width();
        var linkWidth = $('a.scroll-up').width();

        if (contentWidth+linkWidth < documentWidth) {
            var rightMargin = (documentWidth - contentWidth) / 2;
            $('a.scroll-up').css('right', rightMargin - linkWidth);
        } else {
            $('a.scroll-up').css('right', '13px');
        }
    },

    closeFlash: function() {
        $('body').removeClass('has-flash');
        $('header .flash').remove();
    },

    addFlash: function(type, text) {
        var close = $('<a />')
            .html('<i></i>')
            .addClass('close')
            .click(function() {
                app.closeFlash();
            });

        var message = $('<span />')
            .addClass('message')
            .html(text)
            .append(close);

        var flash = $('<div />')
            .addClass('flash')
            .addClass(type)
            .css('display', 'none')
            .append(message);

        $('body').addClass('has-flash');
        $('header > .flash').remove();
        $('header').prepend(flash);
        $('header >.flash').fadeIn(500);
    },

    initializeClipboard: function() {
        var supported = document.queryCommandSupported('copy');

        if (!supported) {
            return;
        }

        $('html').addClass('clipboard-supported');

        var clipboard = new Clipboard('.clipboard');

        clipboard.on('success', function(e) {
            var element = $(e.trigger).attr('data-clipboard-element');

            if (element.length) {
                app.addFlash('success', element + ' wurde in die Zwischenablage kopiert.');
            }

            e.clearSelection();
        });
    },

    initializeInputTouched: function() {
        $('input,textarea').focus(function() {
            $(this).addClass('touched');
        });
    },

    initializeInsiderTile: function() {
        $('ul.shops').on('click', 'li.insider', function(event) {
            var tag = $(event.target).prop('tagName').toLowerCase();

            if ('a' == tag) {
                return;
            }

            location.href = laroute.route('register');
        });
    },

    adjustHeaderHeight: function() {
        $(document).scroll(function() {
            var body = $('body');
            var header = $('header');
            var isSticky = body.hasClass('sticky-header');
            var scrollPos = $(document).scrollTop();

            if (scrollPos >= 200 && !isSticky) {
                body.addClass('sticky-header');
                app.updateTopBarHeight();
            } else if (scrollPos <= 0 && isSticky) {
                body.removeClass('sticky-header');
                app.updateTopBarHeight();
            }
        });
    },

    updateTopBarHeight: function() {
        var topbar = $('.top-bar');
        var isExpanded = topbar.hasClass('expanded');

        if (isExpanded) {
            topbar.removeClass('expanded');
            topbar.css('height', '');
        }

        topbar.data('height', topbar.height());
    },


    disableButtons: function(form) {
        form.find('button').prop('disabled', true);
    },

    enableButtons: function(form) {
        form.find('button').prop('disabled', false);
    },

    initializeAjaxForms: function() {
        $('form.ajax').each(function() {
            var form = $(this);
            form.submit(function() {
                app.disableButtons(form);
                $.post(form.attr('action'), form.serialize(), function(data) {
                    location.reload();
                }).fail(function(response) {
                    var json = $.parseJSON(response.responseText);

                    form.find('small.error').remove();

                    for (var field in json) {
                        var input = form.find('input[name=' + field + ']');
                        var small = $('<small />')
                            .hide()
                            .addClass('error')
                            .text(json[field]);

                        input.after(small);
                        input.parent().addClass('error');
                    }

                    form.find('small.error').fadeIn();

                    app.enableButtons(form);
                });

                return false;
            });
        });
    },

    initializeHashScrolling: function() {
        $('a.scroll-to').click(function() {
            app.scrollToHash();
        })

        $(window).on('hashchange', function() {
            app.scrollToHash();
            return false;
        });

        app.scrollToHash();
    },

    scrollToHash: function(buffer) {
        var hash = location.hash.substr(1);
        buffer   = buffer || 50;

        if (!hash) {
            return;
        }

        var element = $('[data-scroll=' + hash + ']');
        var offset = $('header').height() + buffer;

        if (!element.length) {
            return;
        }

        app.scrollTo(element.offset().top - offset);
    },

    scrollTo: function(position) {
        $('body,html').animate({
            scrollTop: position
        }, 1000);
    },

    initializeDropDowns: function() {
        $('.dropdown .title').each(function() {
            $(this).click(function() {
                var dropdown = $(this).parents('.dropdown');
                var expanded = dropdown.hasClass('expanded');
                var container = dropdown.parents('ul');

                container.find('.dropdown').each(function() {
                    $(this).removeClass('expanded');
                    $(this).addClass('collapsed');
                    $(this).find('.content').slideUp(200);
                });

                if (!expanded) {
                    $(this).parents('.dropdown').removeClass('collapsed');
                    $(this).parents('.dropdown').addClass('expanded');
                    $(this).siblings('.content').slideToggle(200);
                }
            });
        });
    },

    initializeDynamicHeights: function() {
        app.setDynamicHeight();

        $(window).resize(function() {
            app.setDynamicHeight();
        });
    },

    setDynamicHeight: function() {
        var windowHeight = $(window).height();
        $('[data-height]').each(function() {
            var element = $(this);
            var percentage = element.attr('data-height');
            var offset = element.offset().top;
            var height = (windowHeight-offset-10) / 100 * percentage;

            element.css('min-height', height + 'px');
        });
    },

    initializeWishlistButtons: function() {
        $('ul.shops').on('click', '.add-to-wishlist', function() {
            var $this = $(this);
            var id = $this.attr('data-id');
            var li = $(this).parents('li');
            var form = $this.parents('form');
            var url = form.attr('action');
            var data = form.serialize();

            $this.hide();

            $.post(url, data, function() {
                form.find('.remove-from-wishlist').show();
                form.find('.remove-from-wishlist > i').fadeIn(500, function() {
                    li.addClass('added-to-wishlist');
                });
                form.attr('action', laroute.route('wishlist.remove'));
            }).fail(function(result) {
                if (401 == result.status) {
                    var message = 'Um Shops auf Deine persönliche Merkliste zu setzen, musst Du Dich zuvor '
                        + '<a href="' + laroute.route('register') + '"><strong>registrieren</strong></a> '
                        + 'und Dich in Deinen Account '
                        + '<a href="' + laroute.route('login') + '"><strong>einloggen</strong></a>.';

                    app.addFlash('danger', message);
                }
                $this.show();
            });
            return false;
        });

        $('ul.shops').on('click', '.remove-from-wishlist', function() {
            var $this = $(this);
            var id = $this.attr('data-id');
            var li = $(this).parents('li');
            var form = $this.parents('form');
            var url = form.attr('action');
            var data = form.serialize();

            $this.hide();

            $.post(url, data, function() {
                form.find('.heart').hide();
                li.removeClass('added-to-wishlist');
                form.find('.add-to-wishlist').fadeIn(500);
                form.attr('action', laroute.route('wishlist.add'));
            }).fail(function() {
                $this.show();
            });
            return false;
        });
    },

    initializeTeaserArrow: function() {
        $('main .teaser .arrow').each(function() {
            $(this).click(function() {
                var pos = $(this).position().top + $(this).height();

                $('body').animate({
                    scrollTop: pos
                }, 300);

                return false;
            });
        });
    },
    initializeWishlistFilter: function() {
        $('section.wishlist').find('form.filters').each(function() {
            $(this).find('input').change(function() {
                $(this).parents('form').submit();
            });
        });
    },

    initializePasswordFields: function() {
        $('input[type=password]').each(function() {
            var input = $(this);
            input.siblings('.password-toggle').remove();

            var toggle = $('<a />')
                .html('<i />')
                .addClass('password-toggle')
                .css('line-height', input.outerHeight() + 'px')
                .css('top', input.position().top)
                .css('margin-top', input.css('margin-top'))
                .css('left',
                    input.position().left
                    + input.outerWidth()
                    - 50
                );

            toggle.click(function() {
                var type = input.attr('type');

                if ('password' == type) {
                    input.attr('type', 'text');
                } else {
                    input.attr('type', 'password');
                }

                return false;
            });

            $(this).after(toggle);
        });
    },

    initializeFlash: function() {
        $('.flash').each(function() {
            var flash = $(this);

            flash.find('.close').click(function() {
                flash.remove();
                $('body').removeClass('has-flash');
                return false;
            });
        });
    },

    initializeClickableElements: function() {
        $(document).on('click', '[data-click]', function(e) {
            var element = $(e.target);
            var node = element.prop('nodeName').toLowerCase();

            if ($.inArray(node, ['button', 'a', 'i']) >= 0) {
                return true;
            }

            var url = $(this).data('click');
            var target = $(this).data('target');

            if (!target || '_self' == target) {
                location.href = url;
                return false;
            }

            window.open(url);
        });
    },

    initializeShopList: function() {
        var shops = $('ul.shops');

        this.attachHoverClick(shops, 'form', function(event, element) {
            if (element.hasClass('active')) {
                return true;
            }

            event.preventDefault();

            var forms = $('ul.shops > li > form');
            var url = element.parents('li').attr('data-url');
            forms.removeClass('active');

            element.addClass('active');
            element.find('.info').attr('data-click', url);

            return false;

        });

        shops.on('mouseleave', 'form', function() {
            $(this).removeClass('active');
            $(this).find('.info').removeAttr('data-click');
        });
    },

    initializeLoadMore: function() {
        $('form.load-more').each(function() {
            $(this).find('button').click(function() {
                var button = $(this);
                button.prop('disabled', true);

                app.loadMoreInterval = window.setInterval(function() {
                    var pos       = button.offset().top;
                    var scrollPos = $(document).scrollTop() + $(window).height();
                    var buffer    = 800;

                    if ((pos - buffer) < scrollPos) {
                        app.loadMore(button);
                    }
                }, 500);

                app.loadMore(button);

                return false;
            });
        });
    },

    loadMore: function(button) {
        var form = button.parents('form');
        var append = $(form.attr('data-append'));
        var limit = parseInt(form.attr('data-limit'));
        var max = parseInt(form.attr('data-max'));
        var offsetInput = form.find('[name=offset]');
        var offset = parseInt(offsetInput.val());
        var newOffset = offset+limit;
        var data = $.url('?'+form.serialize()).param();
        var params = $.url().param();

        $.extend(data, params);

        if (form.hasClass('loading')) {
            return;
        }

        form.addClass('loading');
        offsetInput.val(newOffset);

        append.children('li').addClass('old');

        $.get(form.attr('action'), data, function(response) {
            var content = $(response);

            append
                .append(content)
                .find('li:not(.old)')
                .css('opacity', 0)
                .animate({opacity: 1.0}, 500);

            if (newOffset >= max) {
                window.clearInterval(app.loadMoreInterval);
                form.remove();
                return;
            }

            form.removeClass('loading');
        }).fail(function() {
            window.clearInterval(app.loadMoreInterval);
            form.remove();
        });

        return false;
    },

    attachHoverClick: function(element, selector, callback) {
        var startPos = 0;
        var buffer = 25;

        element.on('touchstart', selector, function() {
            startPos = $(document).scrollTop();
        });

        element.on('touchend', selector, function(event) {
            var endPos = $(document).scrollTop();
            var diff = Math.abs(startPos - endPos);

            if (diff < buffer) {
                var $this = $(this);
                callback(event, $this);
            }
        });

        element.on('mouseenter', selector, function(event) {
            var $this = $(this);
            window.setTimeout(function() {
                var endPos = $(document).scrollTop();
                var diff = Math.abs(startPos - endPos);

                if (startPos < 1 || diff < buffer) {
                    callback(event, $this);
                }
            }, 50);
        })
    },

    isTouchDevice: function() {
        return $('html').hasClass('touch');
    },

    traceMousePosition: function() {
        $(document).on('click mousemove', function(event) {
            app.mousePosition = {
                x: Math.round(event.pageX / 100 * app.fontSizePercent),
                y: Math.round(event.pageY / 100 * app.fontSizePercent)
            };
        });
    },

    generateUuid: function() {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
            var r = Math.random()*16|0, v = c == 'x' ? r : (r&0x3|0x8);
            return v.toString(16);
        });
    },

    setClientId: function() {
        window.setTimeout(function() {
            var clientId = Cookies.get('clientId');

            if (!clientId) {
                clientId = app.generateUuid();
            }

            Cookies.set('clientId', clientId, { expires: 365 });
        }, 2000);

        if ('undefined' === typeof(ga)) {
            return;
        }

        ga(function(tracker) {
            var clientId = tracker.get('clientId');

            if (!clientId) {
                return;
            }

            Cookies.set('clientId', clientId, { expires: 365 });
        });
    },


    initializeScrollToProduct: function() {
        var isProductArea = $('body').hasClass('products');

        if (!isProductArea) {
            return;
        }

        app.scrollToHash();
    },

    initializeLayer: function() {
        $('.layer').each(function() {
            var layer         = $(this);
            var autoOpen      = layer.attr('data-auto-open');
            var autoOpenCount = app.getLayerAutoOpenCount(layer)

            layer.find('[data-close-layer]').click(function() {
                app.closeLayer(layer);
                return false;
            });

            layer.find('[data-close-layer-forever]').click(function() {
                app.closeLayerForever(layer);
                return false;
            });

            if (autoOpen && autoOpenCount < 3) {
                window.setTimeout(function() {
                    app.increaseLayerAutoOpenCount(layer);
                    app.openLayer(layer);
                }, autoOpen);
            }
        });
    },

    getLayerAutoOpenCount: function(layer) {
        var layerId        = layer.attr('id');
        var autoOpenCounts = Cookies.getJSON('layerAutoOpenCount') || {};

        return autoOpenCounts[layerId] || 0;
    },

    increaseLayerAutoOpenCount: function(layer) {
        var layerId        = layer.attr('id');
        var autoOpenCounts = Cookies.getJSON('layerAutoOpenCount') || {};
        var autoOpenCount  = autoOpenCounts[layerId] || 0;

        autoOpenCounts[layerId] = autoOpenCount + 1;

        Cookies.set('layerAutoOpenCount', autoOpenCounts);
    },

    isLayerHidden: function(layer) {
        var hiddenLayerIds = Cookies.getJSON('hiddenLayers') || [];
        return hiddenLayerIds.indexOf(layer.attr('id')) >= 0;
    },

    openLayer: function(layer) {
        if (app.isLayerHidden(layer)) {
            return;
        }

        $('body').addClass('layer-open');
        layer.fadeIn(500);
    },

    closeLayer: function(layer) {
        $('body').removeClass('layer-open');
        layer.fadeOut(300);
    },

    closeLayerForever: function(layer) {
        app.closeLayer(layer);


        if (app.isLayerHidden(layer)) {
            return;
        }

        var hiddenLayerIds = Cookies.getJSON('hiddenLayers') || [];
        hiddenLayerIds.push(layer.attr('id'));

        Cookies.set('hiddenLayers', hiddenLayerIds, {expires: 365});
    }
};

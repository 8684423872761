(function ( $ ) {
    $.fn.masonry = function() {
        var self      = {};
        var container = $(this);

        self.columnize = function() {
            var original = container.data('masonry-original');
            var children = container.children(':not(.masonry-column)');
            var columns  = container.data('masonry-columns');
            var column   = 1;

            children.each(function() {
                var child = $(this);

                container.children('.masonry-column-'+column).append(child)

                original.push(child);

                column++;

                if (column > columns) {
                    column = 1;
                }
            });
        };

        self.reload = function() {
            self.reset();
            self.initialize();
        },

        self.reset = function() {
            var original = container.data('masonry-original');
            var interval = container.data('masonry-interval');

            container.children('.masonry-column').remove();

            if ('undefined' !== typeof(interval)) {
                window.clearInterval(interval);
            }

            if ('undefined' !== typeof(original) && original.length) {
                original.forEach(function(child) {
                    container.append(child);
                });
            }
        },

        self.getColumnCount = function() {
            var style    = window.getComputedStyle(container[0], ':before');
            var columns  = parseInt(style.getPropertyValue('content').replace(/[^0-9]/g, ''));

            return columns;
        },

        self.initialize = function() {
            var columns  = self.getColumnCount();

            if (columns < 1) {
                return;
            }

            var interval = window.setInterval(function () {
                self.columnize();
            }, 500);

            for (var i = 1; i <= columns; i++) {
                var column = $('<div />')
                    .addClass('masonry-column')
                    .addClass('masonry-column-' + i);

                container.append(column);
            }

            container.data('masonry-columns', columns);
            container.data('masonry-interval', interval);
            container.data('masonry-original', []);
        };

        self.initialize();

        $(window).resize(function() {
            var previousColumns = container.data('masonry-columns');
            var currentColumns  = self.getColumnCount();

            if (previousColumns != currentColumns) {
                self.reload();
            }
        });
    };

}(jQuery));
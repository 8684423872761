(function() {
    'use strict';

    window.setTimeout(function() {
        var $elements = $('[data-insert-text]');

        $elements.each(function () {
            var $element = $(this);
            var text = $element.attr('data-insert-text');

            $element.text(text);
        });
    }, 1000);
})();

(function() {
    'use strict';

    $('a[href*=#]').click(function(e) {
        e.preventDefault();

        var $link = $(this);
        var tag   = $link.attr('href').replace('#', '');

        if (!tag.length) {
            return;
        }

        var $target = $('#' + tag);

        if (!$target.length) {
            $target = $('a[name=' + tag + ']');
        }

        if (!$target.length) {
            return;
        }

        var top = Math.max(Math.ceil($target.offset().top), 0);

        $('body,html').animate({
            scrollTop: top
        }, 800);
    });
})();
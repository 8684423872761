(function() {
    'use strict';

    var buffer    = 500;
    var $document = $(document);
    var $window   = $(window);

    window.setInterval(function () {
        var $elements      = $('[data-lazy-src]');
        var scrollPosition = $document.scrollTop() + $window.height();

        $elements.each(function (index, element) {
            var $element = $(element);
            var position = $element.offset();

            if ((scrollPosition + buffer) >= position.top) {
                var url = $element.attr('data-lazy-src');

                $element.removeAttr('data-lazy-src');
                $element.attr('src', url);

                $element.css('opacity', 1);
            }
        });
    }, 1000);
})();

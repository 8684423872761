(function() {
    'use strict';

    var $form      = $('#filter-form');
    var $dropdowns = $form.find('.dropdown');
    var $body      = $('body');

    $dropdowns.each(function() {
        var $dropdown = $(this);
        var $label    = $dropdown.children('.label');
        var $list     = $dropdown.children('.list');
        var $items    = $list.children('ul').children('li');
        var $input    = $list.children('input');

        $label.click(function() {
            var isExpanded = $dropdown.hasClass('expanded');

            $dropdowns.removeClass('expanded');

            if (isExpanded) {
                $dropdown.removeClass('expanded');
                $body.removeClass('has-mobile-layer');
            } else {
                $dropdown.addClass('expanded');
                $body.addClass('has-mobile-layer');
            }
        });

        $input.keyup(function () {
            var value = $input.val().trim().toLowerCase();

            $items.each(function () {
                var $item = $(this);
                var text  = $item.text().trim().toLowerCase();

                if (text.indexOf(value) >= 0) {
                    $item.removeClass('hidden');
                } else {
                    $item.addClass('hidden');
                }
            });
        });
    });

    window.onunload = function () {
        $dropdowns.removeClass('expanded');
        $body.removeClass('has-tablet-layer');
    };

    window.onpageshow = function () {
        $dropdowns.removeClass('expanded');
        $body.removeClass('has-tablet-layer');
    };
})();

(function() {
    'use strict';

    $.getJSON('/session', function (session) {
        var $links = $('a');

        $links.each(function () {
            var $link = $(this);
            var href = $link.attr('href');

            if (typeof(href) === 'undefined' || !href.length) {
                return;
            }

            href = href.replace('__token__', session.token);

            $link.attr('href', href);
        });
    });
})();

(function() {
    'use strict';

    var $link = $('a[data-offcanvas-link]');

    $link.click(function () {
        var $offCanvas = $('[data-offcanvas]');

        $offCanvas.find('[data-offcanvas-close]').click(function () {
            $offCanvas.removeClass('visible');
        });

        $offCanvas.find('li.has-sub-menu').each(function () {
            var $item    = $(this);
            var $link    = $item.children('a');
            var $subMenu = $item.children('ul');

            $link.click(function() {
                $item.addClass('sub-menu-visible');
            });

            $subMenu.find('.close-sub-menu').click(function () {
                $item.removeClass('sub-menu-visible')
            });
        });

        $offCanvas.addClass('visible');
    });
})();
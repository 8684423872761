(function() {
    'use strict';

    var $inputs = $('[data-search-suggestions]');
    var suggestionClass = 'suggestions';

    $inputs.each(function () {
        var $input = $(this);
        var currentValue = '';
        var loading = false;
        var lastValue = '';
        var interval = null;
        var updateSuggestions = function () {
            var term = currentValue;
            var url = '/suchvorschlaege?term=' + encodeURIComponent(term);
            var $suggestions = $('<ul >').addClass(suggestionClass);

            if (currentValue === lastValue || loading) {
                return;
            }

            lastValue = currentValue;

            if (term.length < 3) {
                return;
            }

            loading = true;

            $.getJSON(url, function (response) {
                var regex = new RegExp('(' + RegExp.quote(response.term) + ')', 'i');

                $.each(response.categories, function (index, category) {
                    var name  = category.name.replace(regex, '<strong>\$1</strong>');

                    var anchor = $('<a />')
                        .html(name)
                        .attr('href', '/' + category.slug);

                    var li = $('<li />')
                        .attr('data-type', 'category')
                        .append(anchor);

                    $suggestions.append(li);
                });

                $input.next('.' + suggestionClass).remove();
                $input.after($suggestions);

                loading = false;
            });
        };

        $input.on('keyup', function () {
            currentValue = $input.val().trim();
        });

        $input.on('focus', function () {
            if (!interval) {
                interval =  window.setInterval(function () {
                    updateSuggestions();
                }, 200);
            }
        });

        $input.on('blur', function () {
            if (interval) {
                clearInterval(interval);
                interval = null;
            }

            window.setTimeout(function() {
                $input.next('.' + suggestionClass).remove();
            }, 1000);
        });
    });
})();

(function() {
    'use strict';

    var key = 'cookieBarAccepted';
    var value = window.localStorage.getItem(key);

    if (value) {
        return;
    }

    $(function() {
        var $bar = $('.cookie-bar');

        $bar.show();

        $bar.children('a').click(function () {
            $bar.hide();

            window.localStorage.setItem(key, 1);

            return false;
        });
    });
})();
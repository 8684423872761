(function() {
    'use strict';

    var $document = $(document);
    var $body = $('body');
    var lastScrollPosition = $document.scrollTop();
    var throttle = 10;
    var minScrollPosition = 50;

    window.setInterval(function () {
        var scrollPosition = $document.scrollTop();
        if ((scrollPosition - throttle) > lastScrollPosition && scrollPosition >= minScrollPosition) {
            $body.addClass('header-hidden');
        } else if ((scrollPosition + throttle) < lastScrollPosition || scrollPosition < minScrollPosition) {
            $body.removeClass('header-hidden');
        }

        lastScrollPosition = scrollPosition;
    }, 100);
})();
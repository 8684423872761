(function() {
    'use strict';

    $(function() {
        var $elements       = $('[data-scroll-fade]');
        var $window         = $(window);
        var windowHeight    = $window.height();
        var inViewClass     = 'in-view';
        var buffer          = 50;
        var updateTriggered = true;

        var updateElements = function () {
            var scrollTop = $window.scrollTop() + windowHeight;

            $elements.each(function() {
                var $element = $(this);

                if ($element.hasClass(inViewClass)) {
                    return;
                }

                if ((scrollTop + buffer) >= $element.offset().top) {
                    $element.addClass(inViewClass);
                }
            });
        };

        $window.scroll(function() {
            updateTriggered = true;
        });

        window.setInterval(function() {
            if (updateTriggered) {
                updateTriggered = false;

                updateElements();
            }
        }, 300);

        updateElements();
    });
})();